<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-title class="justify-center">{{ textCard.title }}</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <!-- <pre>
              {{ partner }}
            </pre> -->
            <v-col cols="12" md="12" class="text-center">
              <v-hover v-slot:default="{}">
                <v-list-item-avatar class="mx-auto" color="grey" size="150">
                  <croppa
                    v-model="croppa"
                    ref="croppa"
                    :width="150"
                    :height="150"
                    :placeholder="$t('form_candidate_upload')"
                    :placeholder-font-size="12"
                    :initial-image="
                      partner.photo
                        ? `${apiUrl}/images/partner/${partner.photo}`
                        : `/avatar.png`
                    "
                  >
                    <!-- 
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-ease-in-ease-out orange darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn
                          text
                          outlined
                          color="white"
                          class
                          style="opacity: 1;"
                          fab
                          large
                          top
                          @click="$refs.croppa.chooseFile()"
                        >
                          <div class="text-center">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  class="mx-2"
                                  color="grey"
                                  v-on="on"
                                >
                                  <v-icon>mdi-camera-plus</v-icon>
                                </v-btn>
                              </template>
                              <span>Carregar foto</span>
                            </v-tooltip>
                          </div>
                        </v-btn>
                      </div>
                    </v-expand-transition> -->
                  </croppa>
                </v-list-item-avatar>
              </v-hover>
              <v-btn
                color="primary"
                style="margin-left: -8%; margin-top: 20%"
                icon
                small
                @click="croppa.chooseFile()"
                ><v-icon>mdi-camera-plus</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="partner.name"
                :readonly="show"
                :label="$t('form_user_name')"
                dense
                prepend-inner-icon="mdi-account"
                outlined
                hide-details
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="partner.email"
                :readonly="show"
                label="Email"
                dense
                prepend-inner-icon="mdi-email"
                outlined
                hide-details
                required
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="partner.telephone"
                :readonly="show"
                :label="$t('form_user_phone')"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                hide-details
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="!show" cols="12" md="6">
              <v-text-field
                v-model.trim="partner.password"
                :label="$t('form_user_pass')"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                hide-details
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="!show" cols="12" md="6">
              <v-text-field
                v-model.trim="repeatPassword"
                :label="$t('form_user_pass_check')"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                hide-details
                :rules="repeatPasswordRules"
              ></v-text-field>
            </v-col>
             <v-col cols="12" md="12">
              <v-autocomplete
                v-model="partner.areasExpertise"
                :items="getTrainingAreas($i18n.locale)"
                item-text="text"
                item-value="key"
                :label="$t('ApplicabilityAreas')"
                dense
                chips
                small-chips
                outlined
                multiple
                :rules="requiredRules"
                hide-details
              >
                <!-- <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        v-if="partner.areasExpertise"
                        :color="partner.areasExpertise.length > 0 ? 'primary' : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("SelectAll") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template> -->
              </v-autocomplete>
            </v-col> 
            <v-col cols="12" md="12">
              <v-textarea
                v-model="partner.office"
                :readonly="show"
                :label="$t('partner_hist')"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                hide-details
                required
                :rules="requiredRules"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn color="primary" text class="mx-2" @click="close()">{{
              show ? $t("close") : $t("cancel")
            }}</v-btn>
            <v-btn
              v-if="!show"
              :disabled="isLoading"
              type="submit"
              color="primary"
              >{{ $t("save") }}</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { UPDATE_PARTNER_MUTATION } from "./../graphql/Mutation";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import { GET_PARTNERS, UPDATED_PARTNER } from "../graphql/Query";
export default {
  name: "AddPartnerDialog",
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    // partner: Object,
    show: Boolean,
  },
  data: () => ({
    apiUrl: API_URL,
    croppa: {},
    repeatPassword: "",
    roles: [],
    partner: [],
  }),
  apollo: {
    partners: {
      query: GET_PARTNERS,
      fetchPolicy: "no-cache",
      result({ data }) {
        let datapartners = data.partners.filter(
          (pt) => pt.user.id === this.currentUser.id
        )[0];
        this.partner = {
          email: datapartners.email,
          name: datapartners.name,
          photo: datapartners.photo,
          telephone: datapartners.telephone,
          areasExpertise: datapartners.areasExpertise,
          office: datapartners.user.office,
          password: datapartners.password,
        };
        // this.partner = datapartners
        // this.notes = data.notes
      },
    },
    $subscribe: {
      message: {
        query: UPDATED_PARTNER,
        result({ data }) {
          this.partner = data.partner_sub;
        },
      },
    },
  },
  watch: {
    partner() {
      this.partner.office = this.partner.user.office;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTrainingAreas: "library/trainingAreas",
    }),
    textCard() {
      return this.partner.id
        ? { title: this.$t("partner_edit") }
        : { title: this.$t("partner_cad") };
    },
    repeatPasswordRules() {
      return [(v) => v === this.partner.password || this.$t("diff_password")];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("required_field"),
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    requiredRules() {
      return [(v) => !!v || this.$t("required_field")];
    },
  },
  methods: {
    ...mapActions({
      setPartnerState: "partner/setPartner",
      updatePartnerState: "partner/updatePartner",
    }),
    closeErrorDialog(value) {
      this.showError = value;
    },
    async close(){
      try {
        const { data } = await this.$apollo.query({
          query: GET_PARTNERS,
          fetchPolicy: "no-cache",
        });
        let datapartners = data.partners.filter(
          (pt) => pt.user.id === this.currentUser.id
        )[0];
        this.partner = {
          email: datapartners.email,
          name: datapartners.name,
          photo: datapartners.photo,
          telephone: datapartners.telephone,
          areasExpertise: datapartners.areasExpertise,
          office: datapartners.user.office,
          password: datapartners.password,
        };
        this.$emit('close')
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit('close')
      }
      
    },
    async handleSubmit() {
      this.isLoading = true;
      if (this.croppa.hasImage()) {
        this.partner.newPhoto = this.croppa.generateDataUrl("image/jpeg", 0.8);
      }
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_PARTNER_MUTATION,
          variables: {
            userInput: Object.assign(
              {},
              { ...this.partner, role: undefined, entity: undefined }
            ),
          },
        });
        this.updatePartnerState(data.updatePartner);
        this.currentUser = data.updatePartner;
        this.success = this.$t("partner_updat_success");
        this.$emit("close");
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
         this.close()
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.show-btns:not(.on-hover) {
  color: #009E91 !important;
}
</style>
