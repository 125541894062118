<template>
  <v-hover v-if="currentUser" v-slot:default="{ hover }">
    <v-card :class="{ 'on-hover': hover }">
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              :class="{'show-btns': hover }"
              color="transparent"
              icon
              v-on="on"
              @click="$emit('edit')"
            >
              <v-icon :class="{'show-btns': hover }">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </v-system-bar>

      <v-list-item class="pt-5">
        <v-list-item-avatar class="mx-auto" color="grey" size="100">
          <img
            :src="
              partner.photo
                ? `${apiUrl}/images/partner/${partner.photo}`
                : `/avatar.png`
            "
            alt
          />
        </v-list-item-avatar>
      </v-list-item>
      <!-- {{partner}}  -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="subtitle-1 font-weight-medium pb-2 text-center"
          >
            {{ partner.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="subtitle-2 text-center pb-4">{{
            partner.email
          }}</v-list-item-subtitle>
          <v-list-item-subtitle class="subtitle-2 text-center">{{
            partner.telephone
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-row justify="center">
        <v-col cols="12" md="8" class="text-center">
          <div>{{ partner.user.office }}</div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8" class="text-center">
          <div>
            <v-row>
              <v-chip-group
                column
                multiple
                v-for="area in partner.areasExpertise"
                :key="area"
              >
                <v-chip filter outlined class="my-0">{{ $t(area) }}</v-chip>
              </v-chip-group>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import { GET_PARTNERS,UPDATED_PARTNER } from "./../graphql/Query";
export default {
  name: "ProfileCard",
  data: () => ({
    apiUrl: API_URL,
    partner: [],
  }),
  apollo: {
    partner: {
      query: GET_PARTNERS,
      fetchPolicy: "no-cache",
      result({ data }) {
        this.partner = data.partners.filter(
          (pt) => pt.user.id === this.currentUser.id
        )[0];
        // this.partner = datapartners
        // this.notes = data.notes
      },
    },
     $subscribe: {
      message: {
        query: UPDATED_PARTNER,
        result({ data }) {
           this.partner = data.partner_sub
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      partnecuer: "partner/getPartners",
    }),
    userId() {
      return this.$root.$data.userId;
    },
  },
};
</script>