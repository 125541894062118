<template>
  <v-container fill-height class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <profile-card @edit="editDialog = true" />
      </v-col>
    </v-row>

    <edit-profile-dialog :dialog="editDialog" :partner="currentUser" @close="editDialog = false" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EditProfileDialog from './../components/EditProfileDialog'
import ProfileCard from './../components/ProfileCard.vue'
export default {
  name: 'Profile',
  components: { EditProfileDialog, ProfileCard },
  data: () => ({
    editDialog: false
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser'
    }),
  }
}
</script>