import gql from 'graphql-tag'

export const GET_PARTNERS = gql `
  query partners {
    partners{
        email
        name
        photo
        telephone
        areasExpertise
        user{
            id
          office
        }
    }
  }
`
export const UPDATED_PARTNER = gql `
subscription partner_sub{
  partner_sub{
    email
    name
    photo
    telephone
    areasExpertise
    user{
        id
      office
    }
  }
}
`